import { responseHandler } from "../_heleper/response-handler";
import { url } from "./apiurl";
// import {authHeader} from '../_heleper/auth-header'

const ajmanApi = {  

  getDepartments: () => {
    const reqOptions = {
      method: "GET",
      headers: {"Content-Type": "application/json" },     
    };
    return fetch(`${url}/amc/cat-tree-list`,reqOptions).then(response=>response.json());
  },
  getHeaderNavs: () => {
    const reqOptions = {
      method: "GET",
      headers: {"Content-Type": "application/json" },     
    };
    return fetch(`${url}/amc/cat-tree-list-column`,reqOptions).then(responseHandler);
  },

  getPopularProducts: (opt = {}) => {
    return fetch(`${url}/home/best-sell?category=${opt?.category??""}&limit=${opt.limit}&zone=amc`).then(responseHandler);
  },

  getVipdealsProducts: (options = {}) =>  fetch(`${url}/home/vip-deals?zone=amc`).then(responseHandler),
  getSpecialProducts: (options = {}) =>  fetch(`${url}/home/special?zone=amc`).then(responseHandler),
  getBelow5000Products: (options = {}) =>  fetch(`${url}/home/below/7000?zone=amc`).then(responseHandler),
 
  
};

export default ajmanApi;
